var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "tableList"
    },
    [
      _c(
        "div",
        [
          _c(
            "ul",
            { staticClass: "childlist parent_header_wrap" },
            _vm._l(_vm.parentFieldsList, function(item, index) {
              return _c("li", { key: index, class: item.class }, [
                _vm._v(_vm._s(item.name))
              ])
            }),
            0
          ),
          _vm._l(_vm.list, function(item, index) {
            return _c(
              "div",
              { key: index, staticClass: "allparentlist" },
              [
                _c(
                  "ul",
                  {
                    ref: "parentList",
                    refInFor: true,
                    staticClass: "parentlist",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.showChildList(index, item)
                      }
                    }
                  },
                  _vm._l(_vm.parents.data, function(pItem, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        class:
                          pItem.isFilterField && item["hide_" + pItem.field]
                            ? pItem.class
                            : pItem.class + " t_center",
                        attrs: {
                          title: pItem.isShowTit
                            ? pItem.name
                              ? pItem.name +
                                "：" +
                                (item[pItem.field] ? item[pItem.field] : "")
                              : item[pItem.field]
                            : ""
                        }
                      },
                      [
                        pItem.isPopover
                          ? _c(
                              "div",
                              { staticClass: "inline" },
                              [
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      width: "300",
                                      trigger: "hover"
                                    },
                                    on: {
                                      show: function($event) {
                                        return _vm.checkApprovalProcess(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("div", [
                                      _c("i", { staticClass: "block" }, [
                                        _vm._v(
                                          "\n                  审批人：\n                  "
                                        ),
                                        _c("em", [
                                          _vm._v(_vm._s(item.fullName))
                                        ])
                                      ]),
                                      _c(
                                        "i",
                                        { staticClass: "block mt10 mb10" },
                                        [
                                          _vm._v(
                                            "\n                  审批时间：\n                  "
                                          ),
                                          _c("em", [
                                            _vm._v(_vm._s(item.approvalTime))
                                          ])
                                        ]
                                      ),
                                      _c("i", { staticClass: "block" }, [
                                        _vm._v(
                                          "\n                  审批意见：\n                  "
                                        ),
                                        _c("em", [_vm._v(_vm._s(item.content))])
                                      ])
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn_popover",
                                        attrs: { slot: "reference" },
                                        slot: "reference"
                                      },
                                      [
                                        _vm._v(
                                          "审批：" +
                                            _vm._s(
                                              _vm.actionsTypeHandle(item.status)
                                            )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : pItem.isPane
                          ? _c("div", {}, [
                              _vm._v("审批状态："),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.approveTypeHandle(item.auditStatus)
                                  )
                                )
                              ])
                            ])
                          : pItem.isUpDown
                          ? _c("div", { staticClass: "inline" }, [
                              item.isShowList
                                ? _c("i", { staticClass: "el-icon-caret-top" })
                                : _c("i", {
                                    staticClass: "el-icon-caret-bottom"
                                  })
                            ])
                          : pItem.isAction && item.actions
                          ? _c("div", { staticClass: "inline" }, [
                              item.actions.hasEdit
                                ? _c("i", {
                                    staticClass: "el-icon-edit",
                                    attrs: { type: "primary", title: "编辑" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.editHandle(index, item)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              item.actions.hasAdd
                                ? _c("i", {
                                    staticClass: "el-icon-circle-plus-outline",
                                    attrs: { type: "primary", title: "添加" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.addHandle(index, item)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              item.actions.hasSubmit
                                ? _c("i", {
                                    staticClass: "el-icon-thumb",
                                    attrs: {
                                      type: "primary",
                                      title: "提交审批"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.submitHandle(index, item)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              item.actions.hasRemove
                                ? _c("i", {
                                    staticClass: "el-icon-close",
                                    attrs: {
                                      type: "primary",
                                      title: "整单解除"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.removeHandle(index, item)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              item.actions.hasDelete
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    attrs: { type: "primary", title: "删除" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteHandle(index, item)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              item.actions.hasCheck
                                ? _c("i", {
                                    staticClass: "el-icon-document",
                                    attrs: { type: "primary", title: "查看" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.checkHandle(index, item)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              item.actions.hasRevoke
                                ? _c("i", {
                                    staticClass: "el-icon-document-delete",
                                    attrs: { type: "primary", title: "撤销" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.revokeHandle(index, item)
                                      }
                                    }
                                  })
                                : _vm._e(),
                              item.actions.hasPayment &&
                              item.payStatus == "未发起"
                                ? _c("i", {
                                    staticClass: "el-icon-bank-card",
                                    attrs: { type: "primary", title: "付款" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.payHandle(index, item)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          : pItem.isFilterField && item.isHideFew
                          ? _c("div", { staticClass: "inline" })
                          : _c("div", { staticClass: "inline" }, [
                              !pItem.name
                                ? _c("span", [
                                    _vm._v(_vm._s(item[pItem.field]))
                                  ])
                                : _c("i", [
                                    _c("em", [
                                      _vm._v(_vm._s(item[pItem.field]))
                                    ])
                                  ])
                            ])
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: item.isShowList,
                        expression: "item.isShowList"
                      }
                    ],
                    staticClass: "childlist"
                  },
                  _vm._l(_vm.fieldsList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _vm.children.type == "double"
                  ? _c(
                      "div",
                      _vm._l(item[_vm.children.name], function(
                        childItem,
                        childIndex
                      ) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.isShowList,
                                expression: "item.isShowList"
                              }
                            ],
                            key: childIndex,
                            staticClass: "list_group"
                          },
                          _vm._l(childItem[_vm.children.childName], function(
                            gChildItem,
                            gChildIndex
                          ) {
                            return _c(
                              "ul",
                              {
                                key: gChildIndex,
                                staticClass: "childcontentlist"
                              },
                              _vm._l(_vm.children.data, function(cItem, i) {
                                return _c(
                                  "li",
                                  { key: i, class: cItem.class },
                                  [
                                    cItem.isChildField
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "inline",
                                            attrs: {
                                              title: childItem[cItem.field]
                                            }
                                          },
                                          [
                                            gChildIndex === 0
                                              ? _c(
                                                  "div",
                                                  { staticClass: "inline" },
                                                  [
                                                    cItem.isAction
                                                      ? _c("div", [
                                                          childItem.actions &&
                                                          childItem.actions
                                                            .hasAdd
                                                            ? _c("i", {
                                                                staticClass:
                                                                  "el-icon-circle-plus-outline",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  title: "添加"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.addItemHandle(
                                                                      childIndex,
                                                                      childItem,
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ])
                                                      : cItem.isQuantityField
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "inline"
                                                          },
                                                          [
                                                            cItem.field_1 &&
                                                            childItem[
                                                              cItem.field_unit
                                                            ] == "米"
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      title:
                                                                        (childItem[
                                                                          cItem
                                                                            .field_1
                                                                        ]
                                                                          ? childItem[
                                                                              cItem
                                                                                .field_1
                                                                            ]
                                                                          : "0") +
                                                                        "包 " +
                                                                        (childItem[
                                                                          cItem
                                                                            .field_2
                                                                        ]
                                                                          ? childItem[
                                                                              cItem
                                                                                .field_2
                                                                            ]
                                                                          : "0") +
                                                                        "米/包 " +
                                                                        (childItem[
                                                                          cItem
                                                                            .field_3
                                                                        ]
                                                                          ? childItem[
                                                                              cItem
                                                                                .field_3
                                                                            ]
                                                                          : "0") +
                                                                        "米"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        (childItem[
                                                                          cItem
                                                                            .field_1
                                                                        ]
                                                                          ? childItem[
                                                                              cItem
                                                                                .field_1
                                                                            ]
                                                                          : "0") +
                                                                          "包 "
                                                                      ) +
                                                                        _vm._s(
                                                                          (childItem[
                                                                            cItem
                                                                              .field_2
                                                                          ]
                                                                            ? childItem[
                                                                                cItem
                                                                                  .field_2
                                                                              ]
                                                                            : "0") +
                                                                            "米/包 "
                                                                        ) +
                                                                        _vm._s(
                                                                          (childItem[
                                                                            cItem
                                                                              .field_3
                                                                          ]
                                                                            ? childItem[
                                                                                cItem
                                                                                  .field_3
                                                                              ]
                                                                            : "0") +
                                                                            "米"
                                                                        )
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      title:
                                                                        (childItem[
                                                                          cItem
                                                                            .field
                                                                        ]
                                                                          ? childItem[
                                                                              cItem
                                                                                .field
                                                                            ]
                                                                          : "0") +
                                                                        childItem[
                                                                          cItem
                                                                            .field_unit
                                                                        ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        (childItem[
                                                                          cItem
                                                                            .field
                                                                        ]
                                                                          ? childItem[
                                                                              cItem
                                                                                .field
                                                                            ]
                                                                          : "0") +
                                                                          childItem[
                                                                            cItem
                                                                              .field_unit
                                                                          ]
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                          ]
                                                        )
                                                      : cItem.isTime
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              childItem[
                                                                cItem.field_1
                                                              ]
                                                            ) +
                                                              "—" +
                                                              _vm._s(
                                                                childItem[
                                                                  cItem.field_2
                                                                ]
                                                              )
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              childItem[
                                                                cItem.field
                                                              ]
                                                            )
                                                          )
                                                        ])
                                                  ]
                                                )
                                              : _c("span")
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "inline",
                                            attrs: {
                                              title: gChildItem[cItem.field]
                                            }
                                          },
                                          [
                                            cItem.isAction
                                              ? _c("div", [
                                                  childItem.actions &&
                                                  childItem.actions.hasAdd
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-circle-plus-outline",
                                                        attrs: {
                                                          type: "primary",
                                                          title: "添加"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.addItemHandle(
                                                              childIndex,
                                                              childItem,
                                                              item
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e()
                                                ])
                                              : cItem.isQuantityField
                                              ? _c(
                                                  "div",
                                                  { staticClass: "inline" },
                                                  [
                                                    cItem.field_1 &&
                                                    gChildItem[
                                                      cItem.field_unit
                                                    ] == "米"
                                                      ? _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              title:
                                                                (gChildItem[
                                                                  cItem.field_1
                                                                ]
                                                                  ? gChildItem[
                                                                      cItem
                                                                        .field_1
                                                                    ]
                                                                  : "0") +
                                                                "包 " +
                                                                (gChildItem[
                                                                  cItem.field_2
                                                                ]
                                                                  ? gChildItem[
                                                                      cItem
                                                                        .field_2
                                                                    ]
                                                                  : "0") +
                                                                "米/包 " +
                                                                (gChildItem[
                                                                  cItem.field_3
                                                                ]
                                                                  ? gChildItem[
                                                                      cItem
                                                                        .field_3
                                                                    ]
                                                                  : "0") +
                                                                "米"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                (gChildItem[
                                                                  cItem.field_1
                                                                ]
                                                                  ? gChildItem[
                                                                      cItem
                                                                        .field_1
                                                                    ]
                                                                  : "0") + "包 "
                                                              ) +
                                                                _vm._s(
                                                                  (gChildItem[
                                                                    cItem
                                                                      .field_2
                                                                  ]
                                                                    ? gChildItem[
                                                                        cItem
                                                                          .field_2
                                                                      ]
                                                                    : "0") +
                                                                    "米/包 "
                                                                ) +
                                                                _vm._s(
                                                                  (gChildItem[
                                                                    cItem
                                                                      .field_3
                                                                  ]
                                                                    ? gChildItem[
                                                                        cItem
                                                                          .field_3
                                                                      ]
                                                                    : "0") +
                                                                    "米"
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              title:
                                                                (gChildItem[
                                                                  cItem.field
                                                                ]
                                                                  ? gChildItem[
                                                                      cItem
                                                                        .field
                                                                    ]
                                                                  : "0") +
                                                                gChildItem[
                                                                  cItem
                                                                    .field_unit
                                                                ]
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                (gChildItem[
                                                                  cItem.field
                                                                ]
                                                                  ? gChildItem[
                                                                      cItem
                                                                        .field
                                                                    ]
                                                                  : "0") +
                                                                  gChildItem[
                                                                    cItem
                                                                      .field_unit
                                                                  ]
                                                              )
                                                            )
                                                          ]
                                                        )
                                                  ]
                                                )
                                              : cItem.isTime
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      gChildItem[cItem.field_1]
                                                    ) +
                                                      "—" +
                                                      _vm._s(
                                                        gChildItem[
                                                          cItem.field_2
                                                        ]
                                                      )
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      gChildItem[cItem.field]
                                                    )
                                                  )
                                                ])
                                          ]
                                        )
                                  ]
                                )
                              }),
                              0
                            )
                          }),
                          0
                        )
                      }),
                      0
                    )
                  : _vm._l(item[_vm.children.name], function(
                      childItem,
                      childIndex
                    ) {
                      return _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.isShowList,
                              expression: "item.isShowList"
                            }
                          ],
                          key: childIndex,
                          staticClass: "childcontentlist"
                        },
                        _vm._l(_vm.children.data, function(cItem, i) {
                          return _c(
                            "li",
                            {
                              key: i,
                              class: cItem.class,
                              attrs: { title: childItem[cItem.field] }
                            },
                            [
                              cItem.isAction
                                ? _c("div", [
                                    childItem.reserveStatus == "Reserve"
                                      ? _c("i", {
                                          staticClass: "el-icon-close",
                                          attrs: {
                                            type: "primary",
                                            title: "解除"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.removeItemHandle(
                                                childIndex,
                                                childItem
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    childItem.actions &&
                                    childItem.actions.hasAdd
                                      ? _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus-outline",
                                          attrs: {
                                            type: "primary",
                                            title: "添加"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.addItemHandle(
                                                childIndex,
                                                childItem,
                                                item
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ])
                                : cItem.isQuantityField
                                ? _c("div", { staticClass: "inline" }, [
                                    cItem.field_1 &&
                                    childItem[cItem.field_unit] == "米"
                                      ? _c(
                                          "span",
                                          {
                                            attrs: {
                                              title:
                                                (childItem[cItem.field_1]
                                                  ? childItem[cItem.field_1]
                                                  : "0") +
                                                "包 " +
                                                (childItem[cItem.field_2]
                                                  ? childItem[cItem.field_2]
                                                  : "0") +
                                                "米/包 " +
                                                (childItem[cItem.field_3]
                                                  ? childItem[cItem.field_3]
                                                  : "0") +
                                                "米"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (childItem[cItem.field_1]
                                                  ? childItem[cItem.field_1]
                                                  : "0") + "包 "
                                              ) +
                                                _vm._s(
                                                  (childItem[cItem.field_2]
                                                    ? childItem[cItem.field_2]
                                                    : "0") + "米/包 "
                                                ) +
                                                _vm._s(
                                                  (childItem[cItem.field_3]
                                                    ? childItem[cItem.field_3]
                                                    : "0") + "米"
                                                )
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            attrs: {
                                              title:
                                                (childItem[cItem.field]
                                                  ? childItem[cItem.field]
                                                  : "0") +
                                                childItem[cItem.field_unit]
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (childItem[cItem.field]
                                                  ? childItem[cItem.field]
                                                  : "0") +
                                                  childItem[cItem.field_unit]
                                              )
                                            )
                                          ]
                                        )
                                  ])
                                : cItem.isOffered
                                ? _c("div", { staticClass: "inline" }, [
                                    childItem.purchaseMeasurementUnit == "米"
                                      ? _c(
                                          "span",
                                          {
                                            attrs: {
                                              title:
                                                (childItem[cItem.field_1]
                                                  ? childItem[cItem.field_1]
                                                  : "0") +
                                                "包 " +
                                                (childItem[cItem.field_2]
                                                  ? childItem[cItem.field_2]
                                                  : "0") +
                                                "米/包 " +
                                                (childItem[cItem.field_3]
                                                  ? childItem[cItem.field_3]
                                                  : "0") +
                                                "米"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                (childItem[cItem.field_1]
                                                  ? childItem[cItem.field_1]
                                                  : "0") + "包 "
                                              ) +
                                                _vm._s(
                                                  (childItem[cItem.field_2]
                                                    ? childItem[cItem.field_2]
                                                    : "0") + "米/包 "
                                                ) +
                                                _vm._s(
                                                  (childItem[cItem.field_3]
                                                    ? childItem[cItem.field_3]
                                                    : "0") + "米"
                                                )
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            attrs: {
                                              title:
                                                childItem[cItem.field_3] +
                                                childItem.purchaseMeasurementUnit
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  childItem[cItem.field_3]
                                                    ? childItem[cItem.field_3]
                                                    : "0"
                                                ) +
                                                " \n                "
                                            ),
                                            childItem.purchaseMeasurementUnit !=
                                            null
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      childItem.purchaseMeasurementUnit
                                                    )
                                                  )
                                                ])
                                              : _c("span", [_vm._v("条")])
                                          ]
                                        )
                                  ])
                                : cItem.isPurchase
                                ? _c("div", { staticClass: "inline" }, [
                                    childItem.purchaseMeasurementUnit == "米"
                                      ? _c(
                                          "span",
                                          {
                                            attrs: {
                                              title:
                                                childItem[cItem.field_1] +
                                                "包  " +
                                                childItem[cItem.field_2] +
                                                "米"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  childItem[cItem.field_1] ==
                                                    null
                                                    ? "0"
                                                    : childItem[cItem.field_1]
                                                ) +
                                                " 包 \n              " +
                                                _vm._s(
                                                  childItem[cItem.field_2]
                                                    ? childItem[cItem.field_2]
                                                    : "0"
                                                ) +
                                                " 米\n            "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            attrs: {
                                              title:
                                                childItem[cItem.field_2] +
                                                childItem.purchaseMeasurementUnit
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  childItem[cItem.field_2]
                                                    ? childItem[cItem.field_2]
                                                    : "0"
                                                ) +
                                                " \n              "
                                            ),
                                            childItem.purchaseMeasurementUnit !=
                                              null &&
                                            childItem.purchaseMeasurementUnit !=
                                              ""
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      childItem.purchaseMeasurementUnit
                                                    )
                                                  )
                                                ])
                                              : _c("span", [_vm._v("条")])
                                          ]
                                        )
                                  ])
                                : cItem.isReserve
                                ? _c("div", { staticClass: "inline" }, [
                                    item.auditStatus == "Approved"
                                      ? _c("div", { attrs: { id: "" } }, [
                                          childItem.reserveStatus == "Reserve"
                                            ? _c(
                                                "em",
                                                { attrs: { title: "预留" } },
                                                [_vm._v("预留")]
                                              )
                                            : _vm._e(),
                                          childItem.reserveStatus == "Release"
                                            ? _c(
                                                "em",
                                                { attrs: { title: "解除" } },
                                                [_vm._v("解除")]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ])
                                : cItem.isReserveBtn
                                ? _c("div", { staticClass: "inline" }, [
                                    item.auditStatus == "Approved"
                                      ? _c(
                                          "div",
                                          { attrs: { id: "" } },
                                          [
                                            childItem.reserveStatus == "Reserve"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { size: "small" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.factorychildupd(
                                                          childItem,
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "em",
                                                      {
                                                        attrs: { title: "解除" }
                                                      },
                                                      [_vm._v("解除")]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                : cItem.isApplication
                                ? _c("div", [
                                    _c("i", [
                                      _vm._v(
                                        _vm._s(
                                          childItem.isFinalDelivery == true
                                            ? "是"
                                            : "否"
                                        )
                                      )
                                    ])
                                  ])
                                : cItem.isTime
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        title:
                                          childItem[cItem.field_1] +
                                          "—" +
                                          childItem[cItem.field_2]
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(childItem[cItem.field_1]) +
                                          "—" +
                                          _vm._s(childItem[cItem.field_2])
                                      )
                                    ]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(childItem[cItem.field]))
                                  ])
                            ]
                          )
                        }),
                        0
                      )
                    })
              ],
              2
            )
          })
        ],
        2
      ),
      !this.list.length && !_vm.loading
        ? _c("p", { staticClass: "empty_tip" }, [_vm._v("暂无数据")])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }